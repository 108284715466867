@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-margin-top: 64px;
}

img,
a {
  user-select: none;
}

#blockRender h1 {
  @apply mb-4 text-4xl;
}

#blockRender h2 {
  @apply mb-4 text-xl font-semibold md:text-2xl;
}

#blockRender h3 {
  @apply mb-2 text-2xl;
}

#blockRender h4 {
  @apply mb-2 text-xl;
}

#blockRender h5 {
  @apply mb-2 text-lg;
}

#blockRender h6 {
  @apply mb-2 text-base;
}

#blockRender p {
  @apply mb-4;
}

#blockRender blockquote {
  @apply my-8 border-l-4 border-gray-200 pl-8 italic;
}

#blockRender img {
  @apply my-8 w-full object-cover;
}
